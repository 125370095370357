import React, { useState, useCallback } from 'react';
import { string, bool, exact, arrayOf, func, shape, number } from 'prop-types';

import { TextInput } from '@atoms/Input';
import { EditSocials, TextModal, ConfirmationModal } from '@molecules';
import { PhotoChanger } from '@organisms';
import { Main } from '@templates';
import { theme } from '@styles';
import { Typography, Flex, Button } from '@mixins';

import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';

import { BackButton } from './styles';

const EditUserProfile = ({
  onFunFactSave,
  funFact,
  superpower,
  avatar,
  onRemovePhoto,
  onSavePhoto,
  isPhotoLoading,
  error,
  onBackClick,
  isConfirmationModalOpen,
  onDiscardChanges,
  hasChanges,
  onMyStorySave,
  onJobTitleChange,
  onSuperPowerChange,
  isLoading,
  firstName,
  lastName,
  myStory,
  jobTitle,
  companyName,
  socials,
  onSave,
}) => {
  const [isMyStoryModalOpen, setIsMyStoryModalOpen] = useState(false);
  const [isFunFactModalOpen, setIsFunFactModalOpen] = useState(false);

  const handleMyStoryModalChangeState = useCallback(() => {
    setIsMyStoryModalOpen(prevValue => !prevValue);
  }, []);

  const handleFunFactModalChangeState = useCallback(() => {
    setIsFunFactModalOpen(prevValue => !prevValue);
  }, []);

  const handleDiscardChanges = useCallback(() => {
    onDiscardChanges();
  }, []);

  const handleMyStorySave = useCallback(newMyStory => {
    onMyStorySave(newMyStory);
  }, []);

  const handleFunFactSave = useCallback(newFunFact => {
    onFunFactSave(newFunFact);
  }, []);

  return (
    <Main isLoading={isLoading} error={error}>
      <Flex mb={[25, 30, 40]} width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" justifyContent="center">
          <Flex width="fit-content" mb={[20, 25, 30]} onClick={onBackClick} pointer alignItems="center">
            <BackButton mr={['9px', 11, 13]} color={theme.color.gray[500]} SVG={Dropdown} />
            <Typography variant="proximaNova-400">Back</Typography>
          </Flex>
          <Typography variant="proximaNova-600" fontSize={[26, 30, 34]}>
            Edit profile
          </Typography>
        </Flex>
        <Button
          py={['8px', 10, 12]}
          px={[40, 50, 60]}
          variant="primary"
          fontSize={[12, 14, 16]}
          onClick={onSave}
          disabled={!hasChanges}>
          Save
        </Button>
      </Flex>
      <Flex alignItems="center" flexDirection="column" width="100%">
        <Flex flexDirection="column" alignItems="center" width="100%" maxWidth={1024}>
          <Flex
            alignItems="center"
            flexDirection="column"
            paddingY={[10, 15, 20]}
            backgroundColor={theme.color.darkGreen}
            width="100%"
            height={[170, 230, 300]}>
            <Flex width={[60, 100, 140]} height={[60, 100, 140]} marginBottom={20}>
              <PhotoChanger
                isLoading={isPhotoLoading}
                isEditable
                isHidden={false}
                isCompany={false}
                avatar={avatar}
                firstName={firstName}
                lastName={lastName}
                onSavePhoto={onSavePhoto}
                onRemovePhoto={onRemovePhoto}
              />
            </Flex>
            <Typography
              color={theme.color.white}
              mb={[10, 15]}
              lineHeight={1}
              variant="garamond-500"
              fontSize={[18, 25, 30, 34]}
              wordBreak="break-word">
              {`${firstName} ${lastName}`}
            </Typography>
            <Typography
              color={theme.color.white}
              lineHeight={1}
              mb={10}
              variant="proximaNova-600"
              fontSize={[12, 16, 20]}>
              {companyName.replace(/\s/g, ' ')}
            </Typography>
            <Typography
              lineHeight={1}
              color={jobTitle ? theme.color.white : theme.color.gray[100]}
              variant="proximaNova-400"
              fontSize={[12, 14, 16]}>
              {jobTitle || 'My big-fat-greek title'}
            </Typography>
          </Flex>
          <Flex
            py={[20, 30, 40]}
            px={[25, 40, 55]}
            border={`1px solid ${theme.color.gray[100]}`}
            width="100%"
            justifyContent="center">
            <Flex width="100%" flexDirection="column" maxWidth={[220, 340, 480]}>
              <Typography mb={['9px', 12, 15]} variant="garamond-500" fontSize={['16px', '18px', '20px']}>
                Here’s a fun fact about me
              </Typography>
              <Typography fontSize={[10, 14, 16]} variant="proximaNova-400">
                {funFact}
              </Typography>
              <Typography
                pointer
                onClick={handleFunFactModalChangeState}
                mb={[25, 35, 45]}
                fontSize={[10, 14, 16]}
                color={theme.color.darkGreen}
                variant="proximaNova-400">
                {myStory ? 'Edit' : 'Enter fun fact'}
              </Typography>
              <Typography mb={['9px', 12, 15]} variant="garamond-500" fontSize={['16px', '18px', '20px']}>
                I don’t mean to brag but I have a superpower
              </Typography>
              <TextInput placeholder="Enter your skills" value={superpower} onChange={onSuperPowerChange} />
              <Typography
                mb={['9px', 12, 15]}
                mt={[30, 40, 50]}
                variant="garamond-500"
                fontSize={['16px', '18px', '20px']}>
                Role
              </Typography>
              <TextInput placeholder="Enter job title" value={jobTitle} onChange={onJobTitleChange} />
              <Typography
                mb={['9px', 12, 15]}
                mt={[30, 40, 50]}
                variant="garamond-500"
                fontSize={['16px', '18px', '20px']}>
                My Story
              </Typography>
              <Typography fontSize={[10, 14, 16]} variant="proximaNova-400">
                {myStory}
              </Typography>
              <Typography
                pointer
                onClick={handleMyStoryModalChangeState}
                mb={[25, 35, 45]}
                fontSize={[10, 14, 16]}
                color={theme.color.darkGreen}
                variant="proximaNova-400">
                {myStory ? 'Edit' : 'Tell us about yourself'}
              </Typography>
              <Typography mb="7px" variant="garamond-500" fontSize={['16px', '18px', '20px']}>
                Don&apos;t Be a Stranger
              </Typography>
              <EditSocials isCompany={false} socials={socials} />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {(isMyStoryModalOpen || isFunFactModalOpen) && (
        <TextModal
          title={isMyStoryModalOpen ? 'My Story' : 'Here’s a fun fact about me'}
          inputPlaceholder={isMyStoryModalOpen ? 'Tell us about yourself' : 'Enter fun fact'}
          onSave={isMyStoryModalOpen ? handleMyStorySave : handleFunFactSave}
          isOpen={isMyStoryModalOpen || isFunFactModalOpen}
          onModalClose={isMyStoryModalOpen ? handleMyStoryModalChangeState : handleFunFactModalChangeState}
          initialText={isMyStoryModalOpen ? myStory : funFact}
        />
      )}
      <ConfirmationModal
        title="Leaving without saving?"
        text="If you quit this page now, your changes won’t be saved."
        onPrimaryButtonClick={onSave}
        onSecondaryButtonClick={handleDiscardChanges}
        isOpen={isConfirmationModalOpen}
        primaryButtonText="Save changes"
        secondaryButtonText="Discard changes"
      />
    </Main>
  );
};

EditUserProfile.defaultProps = {
  funFact: '',
  superpower: '',
  companyName: '',
  firstName: '',
  lastName: '',
  myStory: '',
  jobTitle: '',
  avatar: '',
  socials: [],
  error: {},
};

EditUserProfile.propTypes = {
  onFunFactSave: func.isRequired,
  onSuperPowerChange: func.isRequired,
  funFact: string,
  superpower: string,
  avatar: string,
  onRemovePhoto: func.isRequired,
  onSavePhoto: func.isRequired,
  isPhotoLoading: bool.isRequired,
  error: exact({
    status: number,
    message: string,
  }),
  onBackClick: func.isRequired,
  isConfirmationModalOpen: bool.isRequired,
  onDiscardChanges: func.isRequired,
  hasChanges: bool.isRequired,
  onJobTitleChange: func.isRequired,
  onMyStorySave: func.isRequired,
  onSave: func.isRequired,
  socials: arrayOf(
    exact({
      name: string,
      value: string,
      placeholder: string,
      handler: func,
      icon: shape({}),
    }),
  ),
  companyName: string,
  myStory: string,
  jobTitle: string,
  isLoading: bool.isRequired,
  firstName: string,
  lastName: string,
};

export default EditUserProfile;
