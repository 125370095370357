import React, { useCallback } from 'react';
import { string, bool, exact, arrayOf, number, func, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { Icon } from '@atoms';
import { Socials } from '@molecules';
import { PhotoChanger } from '@organisms';
import { Main } from '@templates';
import { theme } from '@styles';
import { routes } from '@constants';
import { Typography, Flex } from '@mixins';

import { ReactComponent as Edit } from '@assets/svgs/Edit.svg';
import { ReactComponent as Dropdown } from '@assets/svgs/Dropdown.svg';
import Gif from '@assets/gifs/no-profile-details.gif';

import { EditButton } from '../CompanyProfile/styles';
import { BackButton } from '../EditUserProfile/styles';

const UserProfile = ({
  error,
  isHidden,
  isCompanyHidden,
  isPhotoLoading,
  isEditable,
  userId,
  onRemovePhoto,
  onSavePhoto,
  email,
  isLoading,
  firstName,
  lastName,
  myStory,
  superpower,
  funFact,
  jobTitle,
  avatar,
  companyName,
  companyId,
  socials,
  from,
}) => {
  const history = useHistory();

  const onEdit = useCallback(() => {
    history.push({
      pathname: `${routes.userProfile}/${userId}/edit`,
      ...(from && {
        state: {
          from,
        },
      }),
    });
  }, [from, userId]);

  const handleBackClick = useCallback(() => {
    if (from === routes.knowYourNeighbors || from === routes.companyProfile) {
      history.push({
        pathname: `${routes.companyProfile}/${companyId}`,
        state: {
          from,
        },
      });
    } else {
      history.push(from);
    }
  }, [from, companyId]);

  const hasSocials = socials.find(social => !!social.link);

  const hasProfileDetails = isEditable || myStory || hasSocials || email;

  return (
    <Main isLoading={isLoading} error={error}>
      <Flex mb={[20, 25, 30]} width="100%" alignItems="center">
        {from ? (
          <Flex pointer onClick={handleBackClick} alignItems="center">
            <BackButton mr={['9px', 11, 13]} color={theme.color.gray[500]} SVG={Dropdown} />
            <Typography variant="proximaNova-400" fontSize={[12, 14, 16]}>
              Back
            </Typography>
          </Flex>
        ) : (
          <Typography variant="garamond-500" fontSize={[30, 34]}>
            My profile
          </Typography>
        )}
        {isEditable && (
          <EditButton
            marginLeft={15}
            pointer
            onClick={onEdit}
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            width={[25, 35, 48]}
            height={[25, 35, 48]}>
            <Icon onClick={onEdit} SVG={Edit} width={[14, 18, 22]} height={[14, 18, 22]} />
          </EditButton>
        )}
      </Flex>
      <Flex alignItems="center" flexDirection="column" width="100%" maxWidth={1024}>
        <Flex
          alignItems="center"
          flexDirection="column"
          paddingY={[10, 15, 20]}
          backgroundColor={theme.color.darkGreen}
          width="100%"
          height={[170, 230, 300]}>
          <Flex width={[60, 100, 140]} height={[60, 100, 140]} marginBottom={20}>
            <PhotoChanger
              isLoading={isPhotoLoading}
              isEditable={isEditable}
              isHidden={isHidden}
              isCompany={false}
              avatar={avatar}
              firstName={firstName}
              lastName={lastName}
              onSavePhoto={onSavePhoto}
              onRemovePhoto={onRemovePhoto}
            />
          </Flex>
          <Typography
            color={theme.color.white}
            mb={[10, 15]}
            lineHeight={1}
            variant="garamond-500"
            fontSize={[18, 25, 30, 34]}
            wordBreak="break-word">
            {isHidden ? 'Mindspace Member' : `${firstName} ${lastName}`}
          </Typography>
          <Typography
            color={theme.color.white}
            lineHeight={1}
            mb={10}
            variant="proximaNova-600"
            fontSize={[12, 16, 20]}>
            {isCompanyHidden ? 'Member Company' : companyName.replace(/\s/g, ' ')}
          </Typography>
          {((jobTitle && !isHidden) || isEditable) && (
            <Typography
              lineHeight={1}
              color={jobTitle ? theme.color.white : theme.color.gray[100]}
              variant="proximaNova-400"
              fontSize={[12, 14, 16]}>
              {jobTitle || 'My big-fat-greek title'}
            </Typography>
          )}
        </Flex>
        <Flex
          py={[20, 30, 30, 40]}
          px={[25, 40, 40, 55]}
          border={`1px solid ${theme.color.gray[100]}`}
          width="100%"
          justifyContent="center">
          {hasProfileDetails && !isHidden ? (
            <Flex width="100%" alignItems="start" flexDirection="column" maxWidth={[220, 340, 460]}>
              {(isEditable || funFact) && (
                <>
                  <Typography mb={[10, 12, 14]} variant="garamond-500" fontSize={[16, 18, 20]}>
                    Here’s a fun fact about me
                  </Typography>
                  <Typography
                    fontSize={[10, 14, 16]}
                    mb={[25, 35, 45]}
                    variant="proximaNova-400"
                    color={funFact ? theme.color.gray[500] : theme.color.gray[200]}>
                    {funFact || 'What are you great at? edit your profile to add Skills'}
                  </Typography>
                </>
              )}
              {(isEditable || superpower) && (
                <>
                  <Typography mb={[10, 12, 14]} variant="garamond-500" fontSize={[16, 18, 20]}>
                    I don’t mean to brag but I have a superpower
                  </Typography>
                  <Typography
                    fontSize={[10, 14, 16]}
                    mb={[25, 35, 45]}
                    variant="proximaNova-400"
                    color={superpower ? theme.color.gray[500] : theme.color.gray[200]}>
                    {superpower || 'What are you great at? edit your profile to add Skills'}
                  </Typography>
                </>
              )}
              {(isEditable || myStory) && (
                <>
                  <Typography mb={[10, 12, 14]} variant="garamond-500" fontSize={[16, 18, 20]}>
                    My Story
                  </Typography>
                  <Typography
                    fontSize={[10, 14, 16]}
                    mb={[25, 35, 45]}
                    variant="proximaNova-400"
                    color={myStory ? theme.color.gray[500] : theme.color.gray[200]}>
                    {myStory || `Who are you, ${firstName} ${lastName}?`}
                  </Typography>
                </>
              )}
              {(hasSocials || isEditable || email) && (
                <>
                  <Typography mb={[10, 12, 14]} variant="garamond-500" fontSize={[16, 18, 20]}>
                    Don&apos;t Be a Stranger
                  </Typography>
                  <Socials isEditable={isEditable} isCompany={false} socials={socials} email={email} />
                </>
              )}
            </Flex>
          ) : (
            <Flex alignItems="center" alignSelf="center" flexDirection="column">
              <Flex as="img" mb={[20, 30]} width={['260px', '350px']} height={['200px', '260px']} src={Gif} />
              <Typography variant="garamond-500" fontSize={['14px', '18px', '22px']} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Main>
  );
};

UserProfile.defaultProps = {
  funFact: '',
  superpower: '',
  error: {},
  userId: 1,
  companyName: '',
  avatar: '',
  firstName: '',
  lastName: '',
  myStory: '',
  jobTitle: '',
  // skills: [],
  socials: [],
  email: '',
  companyId: 0,
  from: '',
  isCompanyHidden: false,
  isHidden: false,
};

UserProfile.propTypes = {
  funFact: string,
  superpower: string,
  error: exact({
    status: number,
    message: string,
  }),
  isCompanyHidden: bool,
  isHidden: bool,
  isPhotoLoading: bool.isRequired,
  from: string,
  companyId: number,
  isEditable: bool.isRequired,
  userId: number,
  onRemovePhoto: func.isRequired,
  onSavePhoto: func.isRequired,
  email: string,
  socials: arrayOf(
    exact({
      name: string,
      link: string,
      icon: shape({}),
    }),
  ),
  companyName: string,
  myStory: string,
  jobTitle: string,
  isLoading: bool.isRequired,
  firstName: string,
  lastName: string,
  avatar: string,
  // skills: arrayOf(string),
};

export default UserProfile;
